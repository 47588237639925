import { Box, Button, ButtonProps, Grid, Link, SxProps, Theme, useMediaQuery } from '@mui/material';
import React from 'react';

import IconLogo from '../icons/IconLogo';
import IconShare from '../icons/IconShare';
import IconTwitter from '../icons/IconTwitter';

function FilterButton({
  boxSx,
  children,
  onClick,
  sx: _sx,
}: ButtonProps & { boxSx?: SxProps<Theme> }) {
  const autoSize = useMediaQuery('(max-width:664px)');

  const sx: SxProps<Theme> = {
    textTransform: 'none',
    position: 'relative',
    zIndex: 2,
    height: autoSize ? '22px' : '48.5px',
    width: autoSize ? '27px' : '100%',
    minWidth: '27px',
    padding: autoSize ? '2px 5px' : '6px 16px',
    border: autoSize ? '1.8px solid #434470' : '4px solid #434470',
    background: 'white',
    borderRadius: autoSize ? '6.36363px' : '14px',
    color: 'black',
    fontFamily: 'Luckiest Guy',
    ...(_sx || {}),
  };

  return (
    <Box sx={{ position: 'relative', ...boxSx }}>
      <Button onClick={onClick} sx={sx} variant="contained">
        {children}
      </Button>
      <Box
        sx={{
          zIndex: 1,
          width: autoSize ? '27px' : '100%',
          position: 'absolute',
          bottom: autoSize ? '-3px' : '-5.5px',
          height: autoSize ? '22px' : '48.5px',
          background: '#252432',
          borderRadius: autoSize ? '6.36363px' : '14px',
        }}
      ></Box>
    </Box>
  );
}

const Header: React.FC = () => {
  const autoSize = useMediaQuery('(max-width:664px)');

  return (
    <Box
      sx={{
        zIndex: 10,
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
      }}
    >
      <Grid
        alignItems="center"
        container
        height="90px"
        justifyContent="space-between"
        maxWidth="1440px"
        px={autoSize ? 2.5 : 7.5}
      >
        <IconLogo />
        <Box sx={{ display: 'flex' }}>
          <Link
            href="https://twitter.com/intent/tweet?text=https://ottie.altlayer.io/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FilterButton
              boxSx={{
                marginRight: autoSize ? '7.7px' : '28px',
              }}
              sx={{
                '&:hover': {
                  background: '#6667AB',
                  'svg path': {
                    fill: 'white',
                    stroke: 'white',
                  },
                },
              }}
            >
              <IconShare />
            </FilterButton>
          </Link>
          <Link href="https://twitter.com/oh_ottie_" rel="noopener noreferrer" target="_blank">
            <FilterButton
              sx={{
                '&:hover': {
                  background: '#4CA5FF',
                  'svg path': {
                    fill: 'white',
                    stroke: 'white',
                  },
                },
              }}
            >
              <IconTwitter />
            </FilterButton>
          </Link>
        </Box>
      </Grid>
    </Box>
  );
};

export default Header;
