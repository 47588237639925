import { Box, CssBaseline } from '@mui/material';
import React from 'react';

import Footer from './components/Layout/Footer';
import Header from './components/Layout/Header';
import Banner from './pages/home/Banner';
import Home from './pages/home/Home';

function App() {
  return (
    <>
      <CssBaseline />
      <Box>
        <Header />
        <Banner />
        <Home />
        <Footer />
      </Box>
    </>
  );
}

export default App;
