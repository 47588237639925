import { Box, Button, ButtonProps, SxProps, Theme } from '@mui/material';

function FilterButton({
  boxSx,
  children,
  onClick,
  sx: _sx,
}: ButtonProps & { boxSx?: SxProps<Theme> }) {
  const sx: SxProps<Theme> = {
    textTransform: 'none',
    position: 'relative',
    zIndex: 2,
    height: '56px',
    width: '100%',
    border: '4px solid #434470',
    borderRadius: '14px',
    color: 'black',
    lineHeight: '56px',
    fontSize: '28px',
    fontFamily: 'Luckiest Guy',
    '& > span': {
      position: 'relative',
      top: '5px',
    },
    ...(_sx || {}),
  };

  return (
    <Box sx={{ position: 'relative', ...boxSx }}>
      <Button onClick={onClick} sx={sx} variant="contained">
        <span>{children}</span>
      </Button>
      <Box
        sx={{
          zIndex: 1,
          width: '100%',
          position: 'absolute',
          bottom: '-5.5px',
          height: '56px',
          background: '#252432',
          borderRadius: '14px',
        }}
      ></Box>
    </Box>
  );
}

export default FilterButton;
