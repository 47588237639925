import { Box, Divider, Grid, Link } from '@mui/material';
import { useState } from 'react';

import FilterButton from './FilterButton';
import { Category } from './NFTList';

interface Props {
  onCategoryChange: (category: Category) => void;
  category: Category;
}

function FilterDrawer({ category, onCategoryChange }: Props) {
  const [collasped, setCollasped] = useState(true);

  return (
    <Box
      sx={{
        zIndex: 10,
        textAlign: 'center',
        borderTop: '3px solid #000000',
        background: '#868BFF',
        position: 'fixed',
        bottom: '0px',
        left: '0px',
        right: '0px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <FilterButton
          boxSx={{
            maxWidth: '233px',
          }}
          onClick={() => setCollasped(old => !old)}
          sx={{
            maxWidth: '233px',
            background: !collasped ? '#868BFF' : '#F4F2FF',
            color: !collasped ? 'white' : '#868BFF',
            '&:hover': {
              color: 'white',
              background: '#868BFF',
            },
          }}
        >
          HIGHLIGHTS
        </FilterButton>
      </Box>
      <Box
        sx={{
          height: collasped ? '0px' : 'auto',
          position: 'relative',
          top: '10px',
          paddingBottom: 2.5,
        }}
      >
        <Grid container px={4.5} spacing={2}>
          <Grid item xs={6}>
            <FilterButton
              onClick={() => onCategoryChange(Category.OneToOne)}
              sx={{
                background: category === Category.OneToOne ? '#484CAD' : '#F4F2FF',
                color: category === Category.OneToOne ? 'white' : 'black',
                '&:hover': {
                  color: 'white',
                  background: '#484CAD',
                },
              }}
            >
              1 / 1
            </FilterButton>
          </Grid>
          <Grid item xs={6}>
            <FilterButton
              onClick={() => onCategoryChange(Category.Invisible)}
              sx={{
                background: category === Category.Invisible ? '#484CAD' : '#F4F2FF',
                color: category === Category.Invisible ? 'white' : 'black',
                '&:hover': {
                  color: 'white',
                  background: '#484CAD',
                },
              }}
            >
              INVISIBLE
            </FilterButton>
          </Grid>
          <Grid item xs={6}>
            <FilterButton
              onClick={() => onCategoryChange(Category.Golden)}
              sx={{
                background: category === Category.Golden ? '#484CAD' : '#F4F2FF',
                color: category === Category.Golden ? 'white' : 'black',
                '&:hover': {
                  color: 'white',
                  background: '#484CAD',
                },
              }}
            >
              GOLDEN
            </FilterButton>
          </Grid>
          <Grid item xs={6}>
            <FilterButton
              onClick={() => onCategoryChange(Category.Others)}
              sx={{
                background: category === Category.Others ? '#484CAD' : '#F4F2FF',
                color: category === Category.Others ? 'white' : 'black',
                '&:hover': {
                  color: 'white',
                  background: '#484CAD',
                },
              }}
            >
              OTHERS
            </FilterButton>
          </Grid>
        </Grid>

        <Divider
          sx={{
            marginTop: '20px',
            marginBottom: '24.5px',
            borderColor: 'white',
          }}
        />

        <Box px={3.75}>
          <Link
            href="https://opensea.io/collection/oh-ottie"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FilterButton
              boxSx={{
                marginBottom: '35.5px',
              }}
              sx={{
                background: 'white',
                color: '#01D1FF',
                '&:hover': {
                  color: 'white',
                  background: '#01D1FF',
                },
              }}
            >
              OpenSea
            </FilterButton>
          </Link>
          <Link
            href="https://blur.io/collection/oh-ottie"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FilterButton
              sx={{
                background: 'white',
                color: '#FF8A00',
                '&:hover': {
                  color: 'white',
                  background: '#FF8A00',
                },
              }}
            >
              Blur
            </FilterButton>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default FilterDrawer;
