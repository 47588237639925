import { useMediaQuery } from '@mui/material';
import React from 'react';

import BannerPng from '../../assets/Banner_BG.jpg';
import CoconutGif from '../../assets/coconut.gif';

function Banner() {
  const autoSize = useMediaQuery('(max-width:664px)');

  return (
    <div
      style={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <img
        src={CoconutGif}
        style={{
          zIndex: 1,
          position: 'absolute',
          right: autoSize ? '-60px' : '-220px',
          top: autoSize ? '-26px' : '-90px',
          width: autoSize ? '158px' : '531px',
        }}
      />
      <img
        src={CoconutGif}
        style={{
          zIndex: 1,
          transform: 'scaleX(-1)',
          position: 'absolute',
          left: autoSize ? '-60px' : '-220px',
          top: autoSize ? '-26px' : '-90px',
          width: autoSize ? '158px' : '531px',
        }}
      />
      <img
        src={BannerPng}
        style={{
          width: '100%',
        }}
      />
    </div>
  );
}

export default Banner;
