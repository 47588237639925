import { SvgIcon, SvgIconProps, useMediaQuery } from '@mui/material';

export default function IconTwitter({ ...props }: SvgIconProps) {
  const autoSize = useMediaQuery('(max-width:664px)');

  return (
    <SvgIcon
      sx={{ height: autoSize ? 16.5 : 33, width: autoSize ? 20 : 40 }}
      viewBox="0 0 40 33"
      {...props}
    >
      <g clipPath="url(#clip0_416_2348)">
        <path
          d="M12.5158 32.5666C27.3317 32.5666 35.4339 20.1311 35.4339 9.34392C35.4339 8.98875 35.4249 8.63813 35.4114 8.28752C36.9842 7.13549 38.3503 5.6966 39.4288 4.06191C37.9864 4.71305 36.4315 5.15018 34.8048 5.34598C36.4675 4.33511 37.7437 2.73685 38.3459 0.833503C36.791 1.76696 35.0654 2.44998 33.232 2.81426C31.7625 1.22965 29.6684 0.237 27.3542 0.237C22.9054 0.237 19.2969 3.89343 19.2969 8.3968C19.2969 9.03884 19.3688 9.65811 19.5081 10.2592C12.8124 9.91766 6.87617 6.67104 2.9037 1.73053C2.21167 2.9372 1.81172 4.33967 1.81172 5.8332C1.81172 8.66545 3.23175 11.1653 5.39773 12.627C4.07657 12.586 2.8363 12.2172 1.74881 11.607C1.74881 11.6389 1.74881 11.6753 1.74881 11.7117C1.74881 15.6641 4.52595 18.9654 8.21081 19.7122C7.53226 19.8988 6.82225 19.999 6.08977 19.999C5.57299 19.999 5.06519 19.9489 4.57538 19.8533C5.59995 23.0954 8.57481 25.4541 12.0979 25.5224C9.33874 27.7126 5.86957 29.0149 2.09483 29.0149C1.44324 29.0149 0.805126 28.9784 0.171509 28.901C3.73505 31.2142 7.96815 32.5666 12.5158 32.5666Z"
          fill="#4CA5FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_416_2348">
          <rect
            fill="white"
            height="32.3296"
            transform="translate(0.171509 0.237)"
            width="39.2573"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
