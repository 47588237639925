import { createTheme } from '@mui/material';

import { muiLink } from './MuiLink';
import { muiTypography } from './MuiTypography';
import { Colors } from './types';

const colors: Colors = {
  schema: {
    primary: '#6667AB',
    secondary: '#9092FE',
    success: '#39BA72',
    info: '#9092FE',
    error: '#FF6F7D',
    failure: '#F83B4C',
  },
  functional: {
    text: {
      primary: '#FFFFFF',
      link: '#9092FE',
      second: '#AFB0CC',
      third: '#A4A4B2',
      warning: '#979797',
      disabled: '#3D3D49',
      secondDisabled: '#DDDDE3',
    },
    subject: {
      first: '#979797',
      second: '#7E7E8C',
      third: '#646471',
    },
    container: {
      default: '#28282F',
      primary: '#1C1C1F',
      second: '#3D3D49',
      third: '#17171c',
      firth: '#4C4C58',
      sixth: '#D8D8D8',
      disabled: '#4C4C58',
    },
  },
};

export const theme = createTheme({
  colors,
  components: {
    MuiLink: muiLink(colors),
    MuiTypography: muiTypography(colors),
  },
});
