import { SvgIcon, SvgIconProps, useMediaQuery } from '@mui/material';

export default function IconShare({ ...props }: SvgIconProps) {
  const autoSize = useMediaQuery('(max-width:664px)');

  return (
    <SvgIcon
      sx={{ height: autoSize ? 14 : 28, width: autoSize ? 13.5 : 27 }}
      viewBox={'0 0 27 28'}
      {...props}
    >
      <g clipPath="url(#clip0_416_9229)">
        <path
          d="M23.085 19.6848C21.8413 19.6848 20.7385 20.3343 20.0952 21.3145L7.33963 14.9551C7.45604 14.5936 7.5173 14.2077 7.5173 13.8094C7.5173 13.4112 7.44991 13.0007 7.32125 12.627L20.1136 6.07763C20.7631 7.04564 21.8536 7.67668 23.085 7.67668C25.0762 7.67668 26.6936 6.02249 26.6936 3.98846C26.6936 1.95442 25.0762 0.300232 23.085 0.300232C21.0939 0.300232 19.4765 1.95442 19.4765 3.98846C19.4765 4.40507 19.5439 4.79717 19.6725 5.17089L6.88626 11.7202C6.23684 10.7522 5.1463 10.1212 3.91485 10.1212C1.9237 10.1212 0.306274 11.7754 0.306274 13.8094C0.306274 15.8435 1.9237 17.4976 3.91485 17.4976C5.15856 17.4976 6.26135 16.8482 6.90464 15.868L19.6603 22.2335C19.5439 22.595 19.4826 22.981 19.4826 23.3792C19.4826 25.4132 21.1 27.0674 23.0912 27.0674C25.0823 27.0674 26.6997 25.4132 26.6997 23.3792C26.6997 21.3452 25.0823 19.691 23.0912 19.691L23.085 19.6848ZM20.469 3.98846C20.469 2.51194 21.6453 1.31112 23.085 1.31112C24.5248 1.31112 25.7011 2.51194 25.7011 3.98846C25.7011 5.46497 24.5248 6.66579 23.085 6.66579C21.6453 6.66579 20.469 5.46497 20.469 3.98846ZM6.53092 13.8094C6.53092 15.2859 5.35461 16.4868 3.91485 16.4868C2.4751 16.4868 1.29879 15.2859 1.29879 13.8094C1.29879 12.3329 2.4751 11.1321 3.91485 11.1321C5.35461 11.1321 6.53092 12.3329 6.53092 13.8094ZM20.7385 22.2151C20.7385 22.2151 20.7385 22.2151 20.7385 22.209C20.7385 22.209 20.7385 22.209 20.7385 22.2029C21.1674 21.3207 22.0558 20.708 23.085 20.708C24.5309 20.708 25.7011 21.9088 25.7011 23.3853C25.7011 24.8618 24.5248 26.0627 23.085 26.0627C21.6453 26.0627 20.469 24.8618 20.469 23.3853C20.469 22.9687 20.567 22.5766 20.7324 22.2213L20.7385 22.2151Z"
          fill="#6667AB"
          stroke="#6667AB"
          strokeMiterlimit="10"
        />
        <path
          d="M23.1831 26.2219C24.7971 26.2219 26.1055 24.9135 26.1055 23.2995C26.1055 21.6855 24.7971 20.3771 23.1831 20.3771C21.5691 20.3771 20.2607 21.6855 20.2607 23.2995C20.2607 24.9135 21.5691 26.2219 23.1831 26.2219Z"
          fill="#6667AB"
          stroke="#6667AB"
          strokeMiterlimit="10"
        />
        <path
          d="M3.93936 16.5725C5.55336 16.5725 6.86176 15.2641 6.86176 13.6501C6.86176 12.0361 5.55336 10.7277 3.93936 10.7277C2.32537 10.7277 1.01697 12.0361 1.01697 13.6501C1.01697 15.2641 2.32537 16.5725 3.93936 16.5725Z"
          fill="#6667AB"
          stroke="#6667AB"
          strokeMiterlimit="10"
        />
        <path
          d="M23.1831 6.86792C24.7971 6.86792 26.1055 5.55952 26.1055 3.94553C26.1055 2.33153 24.7971 1.02313 23.1831 1.02313C21.5691 1.02313 20.2607 2.33153 20.2607 3.94553C20.2607 5.55952 21.5691 6.86792 23.1831 6.86792Z"
          fill="#6667AB"
          stroke="#6667AB"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_416_9229">
          <rect fill="white" height="27.3676" width="27" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
