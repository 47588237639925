import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import IconLogoSecondary from '../icons/IconLogoSecondary';

const Footer: React.FC = () => {
  const theme = useTheme();
  const autoSize = useMediaQuery('(max-width:664px)');

  return (
    <Box
      sx={{
        backgroundColor: '#252433',
      }}
    >
      <Grid
        alignItems="center"
        color={theme.colors.functional.text.third}
        container
        height="60px"
        justifyContent="space-between"
        maxWidth="1440px"
        px={autoSize ? 2.5 : 7.5}
      >
        <IconLogoSecondary />
        <Typography variant="body1">© 2023 by AltLayer</Typography>
      </Grid>
    </Box>
  );
};

export default Footer;
