import { Box, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';

import Filter from './Filter';
import FilterDrawer from './FilterDrawer';
import NFTList, { Category } from './NFTList';

function Home() {
  const [category, setCategory] = useState(Category.OneToOne);
  const showFilter = useMediaQuery('(min-width:1040px)');
  const autoSize = useMediaQuery('(max-width:664px)');

  return (
    <Box margin="0 auto" maxWidth="1440px" p={autoSize ? 2.5 : 7.5}>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {showFilter ? (
          <Box
            sx={{
              marginRight: '30px',
            }}
          >
            <Filter category={category} onCategoryChange={setCategory} />
          </Box>
        ) : (
          <FilterDrawer category={category} onCategoryChange={setCategory} />
        )}
        <Box
          sx={{
            flex: 1,
          }}
        >
          <NFTList category={category} />
        </Box>
      </Box>
    </Box>
  );
}

export default Home;
