import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconLogoSecondary({ ...props }: SvgIconProps) {
  return (
    <SvgIcon sx={{ height: 20, width: 114 }} viewBox="0 0 114 20" {...props}>
      <path
        d="M10.9769 19.9848C11.1227 19.9848 11.2655 19.9544 11.3994 19.8996C11.5333 19.8418 11.6553 19.7596 11.7594 19.6531L20.4154 10.8004C20.5195 10.6939 20.5999 10.5691 20.6564 10.4322C20.7129 10.2952 20.7397 10.1461 20.7397 10C20.7397 9.85397 20.71 9.70485 20.6564 9.56791C20.5999 9.43096 20.5195 9.30619 20.4154 9.19968L11.7594 0.346969C11.6553 0.240457 11.5333 0.15829 11.3994 0.100469C11.2655 0.0426477 11.1197 0.0152588 10.9769 0.0152588V19.9848Z"
        fill="white"
      />
      <path
        d="M9.76289 16.2599C9.48021 16.2599 9.19753 16.193 8.98032 16.053L0.324387 10.5021C-0.107071 10.2252 -0.107071 9.77481 0.324387 9.49788L8.98032 3.9501C9.19753 3.81011 9.48021 3.74316 9.76289 3.74316V16.2599Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M9.76332 12.5167C9.48064 12.5167 9.19796 12.4498 8.98074 12.3098L0.324814 6.75898C-0.106644 6.48205 -0.106644 6.03165 0.324814 5.75472L8.98074 0.206939C9.19499 0.0669507 9.48064 0 9.76332 0V12.5167Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M9.76289 20C9.48021 20 9.19753 19.9331 8.98032 19.7931L0.324387 14.2453C-0.107071 13.9684 -0.107071 13.518 0.324387 13.241L8.98032 7.69021C9.19753 7.55023 9.48021 7.48328 9.76289 7.48328V20Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M24.111 16.832L29.2171 3.16797H32.8265L37.9296 16.832H34.4422L33.2371 13.734H28.7767L27.5954 16.832H24.111ZM29.235 11.2081H32.7818L30.9965 6.45464L29.232 11.2081H29.235Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M39.096 16.832V3.16797H42.4376V14.2027H48.7458V16.832H39.096Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M66.3403 16.832L71.4434 3.16797H75.0527L80.1558 16.832H76.6685L75.4663 13.734H71.006L69.8247 16.832H66.3403ZM71.4642 11.2081H75.0111L73.2257 6.45464L71.4612 11.2081H71.4642Z"
        fill="white"
      />
      <path
        d="M82.459 16.832V11.8046L77.6653 3.16797H81.1497L84.1372 8.83443L87.1247 3.16797H90.612L85.8184 11.8046V16.832H82.459Z"
        fill="white"
      />
      <path
        d="M91.7308 16.832V3.16797H101.586V5.80035H95.0694V8.66706H100.818V11.339H95.0694V14.2027H101.586V16.835L91.7308 16.832Z"
        fill="white"
      />
      <path
        d="M102.714 16.832V3.16797H109.394C110.971 3.16797 112.131 3.59098 112.878 4.44003C113.625 5.28909 113.997 6.42725 113.997 7.85756C113.997 8.77661 113.768 9.58306 113.313 10.2769C112.857 10.9708 112.233 11.4851 111.444 11.8259C111.628 11.9689 111.786 12.1455 111.914 12.3463C112.063 12.6019 112.191 12.8667 112.298 13.1406L113.875 16.832H110.388L108.894 13.3536C108.769 13.0584 108.611 12.8423 108.418 12.7054C108.224 12.5715 107.948 12.5015 107.588 12.5045H106.052V16.832H102.711H102.714ZM106.055 9.87217H108.522C109.174 9.87217 109.682 9.70479 110.048 9.37308C110.414 9.04137 110.599 8.5362 110.599 7.85452C110.599 6.48203 109.956 5.79731 108.671 5.79731H106.058V9.87217H106.055Z"
        fill="white"
      />
      <path
        d="M65.2007 14.2026H58.8954V3.16795H58.8716V3.16187H55.5271V16.8381H58.8716V16.832H65.2007V14.2026Z"
        fill="white"
      />
      <path
        d="M47.0438 5.80035H51.034V16.832H54.322V3.16797H47.0438V5.80035Z"
        fill="white"
        opacity="0.5"
      />
    </SvgIcon>
  );
}
