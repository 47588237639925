import { SvgIcon, SvgIconProps, useMediaQuery, useTheme } from '@mui/material';

export default function IconLogo({ ...props }: SvgIconProps) {
  const theme = useTheme();
  const autoSize = useMediaQuery('(max-width:664px)');

  return (
    <SvgIcon
      sx={{ height: autoSize ? 22 : 44, width: autoSize ? 126 : 252 }}
      viewBox="0 0 252 44"
      {...props}
    >
      <path
        d="M24.2647 43.9665C24.587 43.9665 24.9027 43.8996 25.1987 43.7791C25.4947 43.6519 25.7644 43.4711 25.9946 43.2368L45.1288 23.7608C45.359 23.5265 45.5366 23.252 45.6616 22.9507C45.7865 22.6494 45.8457 22.3214 45.8457 22C45.8457 21.6786 45.78 21.3506 45.6616 21.0493C45.5366 20.748 45.359 20.4735 45.1288 20.2392L25.9946 0.76324C25.7644 0.528913 25.4947 0.348146 25.1987 0.22094C24.9027 0.0937334 24.5804 0.0334778 24.2647 0.0334778V43.9665Z"
        fill={theme.colors.schema.primary}
      />
      <path
        d="M21.5811 35.7717C20.9562 35.7717 20.3313 35.6245 19.8512 35.3165L0.71702 23.1047C-0.236728 22.4954 -0.236728 21.5046 0.71702 20.8953L19.8512 8.69019C20.3313 8.38222 20.9562 8.23492 21.5811 8.23492V35.7717Z"
        fill={theme.colors.schema.primary}
        opacity="0.5"
      />
      <path
        d="M21.5811 27.5368C20.9563 27.5368 20.3314 27.3895 19.8512 27.0816L0.717081 14.8697C-0.236667 14.2605 -0.236667 13.2696 0.717081 12.6604L19.8512 0.455265C20.3248 0.147292 20.9563 0 21.5811 0V27.5368Z"
        fill={theme.colors.schema.primary}
        opacity="0.5"
      />
      <path
        d="M21.5811 44C20.9562 44 20.3313 43.8527 19.8512 43.5447L0.71702 31.3396C-0.236728 30.7304 -0.236728 29.7395 0.71702 29.1302L19.8512 16.9184C20.3313 16.6105 20.9562 16.4632 21.5811 16.4632V44Z"
        fill={theme.colors.schema.primary}
        opacity="0.5"
      />
      <path
        d="M53.2981 37.0304L64.5852 6.96957H72.5638L83.8443 37.0304H76.1354L73.4715 30.2149H63.6117L61.0004 37.0304H53.2981ZM64.6247 24.658H72.4651L68.5186 14.2002L64.6181 24.658H64.6247Z"
        fill={theme.colors.schema.primary}
        opacity="0.5"
      />
      <path
        d="M86.4227 37.0304V6.96957H93.8093V31.2459H107.754V37.0304H86.4227Z"
        fill={theme.colors.schema.primary}
        opacity="0.5"
      />
      <path
        d="M146.647 37.0304L157.928 6.96957H165.906L177.187 37.0304H169.478L166.82 30.2149H156.961L154.349 37.0304H146.647ZM157.974 24.658H165.814L161.868 14.2002L157.967 24.658H157.974Z"
        fill={theme.colors.schema.primary}
      />
      <path
        d="M182.278 37.0304V25.9702L171.681 6.96957H179.384L185.987 19.4358L192.591 6.96957H200.3L189.704 25.9702V37.0304H182.278Z"
        fill={theme.colors.schema.primary}
      />
      <path
        d="M202.773 37.0304V6.96957H224.558V12.7608H210.153V19.0676H222.861V24.9458H210.153V31.2459H224.558V37.0371L202.773 37.0304Z"
        fill={theme.colors.schema.primary}
      />
      <path
        d="M227.051 37.0304V6.96957H241.818C245.304 6.96957 247.869 7.90019 249.52 9.76811C251.171 11.636 251.993 14.14 251.993 17.2867C251.993 19.3086 251.487 21.0828 250.481 22.6093C249.474 24.1357 248.093 25.2672 246.35 26.017C246.758 26.3317 247.106 26.72 247.389 27.1619C247.718 27.7243 248.001 28.3068 248.238 28.9093L251.724 37.0304H244.015L240.713 29.378C240.437 28.7286 240.088 28.2532 239.66 27.9519C239.233 27.6573 238.621 27.5034 237.825 27.51H234.431V37.0304H227.045H227.051ZM234.438 21.7188H239.891C241.331 21.7188 242.456 21.3506 243.265 20.6208C244.074 19.8911 244.482 18.7797 244.482 17.28C244.482 14.2605 243.061 12.7541 240.22 12.7541H234.444V21.7188H234.438Z"
        fill={theme.colors.schema.primary}
      />
      <path
        d="M144.128 31.2459H130.19V6.96957H130.137V6.95618H122.744V37.0438H130.137V37.0304H144.128V31.2459Z"
        fill={theme.colors.schema.primary}
      />
      <path
        d="M103.991 12.7608H112.812V37.0304H120.08V6.96957H103.991V12.7608Z"
        fill={theme.colors.schema.primary}
        opacity="0.5"
      />
    </SvgIcon>
  );
}
