import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

import Nft259 from '../../assets/golden/259.png';
import Nft384 from '../../assets/golden/384.png';
import Nft533 from '../../assets/golden/533.png';
import Nft593 from '../../assets/golden/593.png';
import Nft638 from '../../assets/golden/638.jpg';
import Nft643 from '../../assets/golden/643.png';
import Nft979 from '../../assets/golden/979.png';
import Nft1014 from '../../assets/golden/1014.png';
import Nft1346 from '../../assets/golden/1346.png';
import Nft1382 from '../../assets/golden/1382.png';
import Nft1565 from '../../assets/golden/1565.png';
import Nft1582 from '../../assets/golden/1582.png';
import Nft1990 from '../../assets/golden/1990.png';
import Nft24 from '../../assets/invisible/24.png';
import Nft288 from '../../assets/invisible/288.png';
import Nft379 from '../../assets/invisible/379.png';
import Nft841 from '../../assets/invisible/841.png';
import Nft947 from '../../assets/invisible/947.png';
import Nft1037 from '../../assets/invisible/1037.png';
import Nft1221 from '../../assets/invisible/1221.png';
import Nft1329 from '../../assets/invisible/1329.png';
import Nft1481 from '../../assets/invisible/1481.png';
import Nft1724 from '../../assets/invisible/1724.png';
import Nft1837 from '../../assets/invisible/1837.png';
import Nft1956 from '../../assets/invisible/1956.png';
import Nft793 from '../../assets/one-to-one/793.png';
import Nft1335 from '../../assets/one-to-one/1335.png';
import Nft1926 from '../../assets/one-to-one/1926.jpg';
import Nft28 from '../../assets/others/28.png';
import Nft126 from '../../assets/others/126.png';
import Nft203 from '../../assets/others/203.png';
import Nft239 from '../../assets/others/239.png';
import Nft294 from '../../assets/others/294.png';
import Nft311 from '../../assets/others/311.png';
import Nft339 from '../../assets/others/339.png';
import Nft377 from '../../assets/others/377.png';
import Nft408 from '../../assets/others/408.png';
import Nft430 from '../../assets/others/430.png';
import Nft434 from '../../assets/others/434.png';
import Nft466 from '../../assets/others/466.png';
import Nft613 from '../../assets/others/613.png';
import Nft640 from '../../assets/others/640.png';
import Nft745 from '../../assets/others/745.png';
import Nft770 from '../../assets/others/770.png';
import Nft812 from '../../assets/others/812.png';
import Nft1210 from '../../assets/others/1210.png';
import Nft1300 from '../../assets/others/1300.png';
import Nft1364 from '../../assets/others/1364.png';
import Nft1391 from '../../assets/others/1391.png';
import Nft1424 from '../../assets/others/1424.png';
import Nft1529 from '../../assets/others/1529.png';
import Nft1543 from '../../assets/others/1543.png';
import Nft1828 from '../../assets/others/1828.png';
import Nft1859 from '../../assets/others/1859.png';
import Nft1888 from '../../assets/others/1888.png';

export enum Category {
  Golden = 'Golden',
  Invisible = 'Invisable',
  OneToOne = 'OneToOne',
  Others = 'Others',
}

const categories = {
  [Category.Golden]: [
    {
      src: Nft259,
      name: 'Oh Ottie #259',
    },
    {
      src: Nft384,
      name: 'Oh Ottie #384',
    },
    {
      src: Nft533,
      name: 'Oh Ottie #533',
    },
    {
      src: Nft593,
      name: 'Oh Ottie #593',
    },
    {
      src: Nft643,
      name: 'Oh Ottie #643',
    },
    {
      src: Nft979,
      name: 'Oh Ottie #979',
    },
    {
      src: Nft1014,
      name: 'Oh Ottie #1014',
    },
    {
      src: Nft1346,
      name: 'Oh Ottie #1346',
    },
    {
      src: Nft1382,
      name: 'Oh Ottie #1382',
    },
    {
      src: Nft1565,
      name: 'Oh Ottie #1565',
    },
    {
      src: Nft1582,
      name: 'Oh Ottie #1582',
    },
    {
      src: Nft1990,
      name: 'Oh Ottie #1990',
    },
  ],
  [Category.Invisible]: [
    {
      src: Nft24,
      name: 'Oh Ottie #24',
    },
    {
      src: Nft288,
      name: 'Oh Ottie #288',
    },
    {
      src: Nft379,
      name: 'Oh Ottie #379',
    },
    {
      src: Nft841,
      name: 'Oh Ottie #841',
    },
    {
      src: Nft947,
      name: 'Oh Ottie #947',
    },
    {
      src: Nft1037,
      name: 'Oh Ottie #1037',
    },
    {
      src: Nft1221,
      name: 'Oh Ottie #1221',
    },
    {
      src: Nft1329,
      name: 'Oh Ottie #1329',
    },
    {
      src: Nft1481,
      name: 'Oh Ottie #1481',
    },
    {
      src: Nft1724,
      name: 'Oh Ottie #1724',
    },
    {
      src: Nft1837,
      name: 'Oh Ottie #1837',
    },
    {
      src: Nft1956,
      name: 'Oh Ottie #1956',
    },
  ],
  [Category.OneToOne]: [
    {
      src: Nft638,
      name: 'Oh Ottie #638',
    },
    {
      src: Nft793,
      name: 'Oh Ottie #793',
    },
    {
      src: Nft1335,
      name: 'Oh Ottie #1335',
    },
    {
      src: Nft1926,
      name: 'Oh Ottie #1926',
    },
  ],
  [Category.Others]: [
    { name: 'Oh Ottie #28', src: Nft28 },
    { name: 'Oh Ottie #126', src: Nft126 },
    { name: 'Oh Ottie #203', src: Nft203 },
    { name: 'Oh Ottie #239', src: Nft239 },
    { name: 'Oh Ottie #294', src: Nft294 },
    { name: 'Oh Ottie #311', src: Nft311 },
    { name: 'Oh Ottie #339', src: Nft339 },
    { name: 'Oh Ottie #377', src: Nft377 },
    { name: 'Oh Ottie #408', src: Nft408 },
    { name: 'Oh Ottie #430', src: Nft430 },
    { name: 'Oh Ottie #434', src: Nft434 },
    { name: 'Oh Ottie #466', src: Nft466 },
    { name: 'Oh Ottie #613', src: Nft613 },
    { name: 'Oh Ottie #640', src: Nft640 },
    { name: 'Oh Ottie #745', src: Nft745 },
    { name: 'Oh Ottie #770', src: Nft770 },
    { name: 'Oh Ottie #812', src: Nft812 },
    { name: 'Oh Ottie #1210', src: Nft1210 },
    { name: 'Oh Ottie #1300', src: Nft1300 },
    { name: 'Oh Ottie #1364', src: Nft1364 },
    { name: 'Oh Ottie #1391', src: Nft1391 },
    { name: 'Oh Ottie #1424', src: Nft1424 },
    { name: 'Oh Ottie #1529', src: Nft1529 },
    { name: 'Oh Ottie #1543', src: Nft1543 },
    { name: 'Oh Ottie #1828', src: Nft1828 },
    { name: 'Oh Ottie #1859', src: Nft1859 },
    { name: 'Oh Ottie #1888', src: Nft1888 },
  ],
};

interface Props {
  category: Category;
}

function NFTList({ category }: Props) {
  const autoSize = useMediaQuery('(max-width:664px)');

  return (
    <Box
      sx={{
        display: autoSize ? 'grid' : 'flex',
        columnGap: '14px',
        gridTemplateColumns: '1fr 1fr',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      {categories[category].map(nft => (
        <Box
          key={nft.name}
          sx={{
            '& img': {
              borderRadius: '6px',
              width: autoSize ? '100%' : '272px',
              height: 'auto',
              border: '4px solid #000000',
              filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            },
          }}
        >
          <img src={nft.src} />
          <Typography
            sx={{
              fontFamily: 'Kanit',
              textAlign: 'center',
              padding: autoSize ? '8px 0px 16px 0px' : '20px 0px 30px 0px',
              fontSize: autoSize ? '15px' : '24px',
              lineHeight: autoSize ? '36px' : '22.43px',
            }}
            variant="subtitle1"
          >
            {nft.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

export default NFTList;
