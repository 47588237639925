import { Box, Divider, Link, Typography } from '@mui/material';
import React from 'react';

import FilterButton from './FilterButton';
import { Category } from './NFTList';

interface Props {
  onCategoryChange: (category: Category) => void;
  category: Category;
}

function Filter({ category, onCategoryChange }: Props) {
  return (
    <Box
      sx={{
        background: '#868BFF',
        border: '4px solid #000000',
        borderRadius: '8px',
        padding: 2.5,
        maxWidth: '360px',
        textAlign: 'center',
      }}
    >
      <Typography
        sx={{
          fontSize: '57px',
          lineHeight: '57px',
          color: 'white',
          fontFamily: 'Luckiest Guy',
        }}
      >
        HIGHLIGHTS
      </Typography>
      <Divider
        sx={{
          marginTop: '20px',
          marginBottom: '24.5px',
          borderColor: 'white',
        }}
      />
      <FilterButton
        boxSx={{
          marginBottom: '35.5px',
        }}
        onClick={() => onCategoryChange(Category.OneToOne)}
        sx={{
          background: category === Category.OneToOne ? '#484CAD' : '#F4F2FF',
          color: category === Category.OneToOne ? 'white' : 'black',
          '&:hover': {
            color: 'white',
            background: '#484CAD',
          },
        }}
      >
        1 / 1
      </FilterButton>
      <FilterButton
        boxSx={{
          marginBottom: '35.5px',
        }}
        onClick={() => onCategoryChange(Category.Invisible)}
        sx={{
          background: category === Category.Invisible ? '#484CAD' : '#F4F2FF',
          color: category === Category.Invisible ? 'white' : 'black',
          '&:hover': {
            color: 'white',
            background: '#484CAD',
          },
        }}
      >
        INVISIBLE
      </FilterButton>
      <FilterButton
        boxSx={{
          marginBottom: '35.5px',
        }}
        onClick={() => onCategoryChange(Category.Golden)}
        sx={{
          background: category === Category.Golden ? '#484CAD' : '#F4F2FF',
          color: category === Category.Golden ? 'white' : 'black',
          '&:hover': {
            color: 'white',
            background: '#484CAD',
          },
        }}
      >
        GOLDEN
      </FilterButton>
      <FilterButton
        boxSx={{
          marginBottom: '35.5px',
        }}
        onClick={() => onCategoryChange(Category.Others)}
        sx={{
          background: category === Category.Others ? '#484CAD' : '#F4F2FF',
          color: category === Category.Others ? 'white' : 'black',
          '&:hover': {
            color: 'white',
            background: '#484CAD',
          },
        }}
      >
        OTHERS
      </FilterButton>

      <Divider
        sx={{
          marginTop: '20px',
          marginBottom: '24.5px',
          borderColor: 'white',
        }}
      />

      <Link href="https://opensea.io/collection/oh-ottie" rel="noopener noreferrer" target="_blank">
        <FilterButton
          boxSx={{
            marginBottom: '35.5px',
          }}
          sx={{
            background: 'white',
            color: '#01D1FF',
            '&:hover': {
              color: 'white',
              background: '#01D1FF',
            },
          }}
        >
          OpenSea
        </FilterButton>
      </Link>

      <Link href="https://blur.io/collection/oh-ottie" rel="noopener noreferrer" target="_blank">
        <FilterButton
          boxSx={{
            marginBottom: '35.5px',
          }}
          sx={{
            background: 'white',
            color: '#FF8A00',
            '&:hover': {
              color: 'white',
              background: '#FF8A00',
            },
          }}
        >
          Blur
        </FilterButton>
      </Link>
    </Box>
  );
}

export default Filter;
